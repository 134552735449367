<template>
<div v-if="x != null">
    <v-card class="mx-auto mt-5" max-width="1100">
        <v-row class="pa-5">
            <v-col v-for="(att, i) in x.attachments" :key="i" class="d-flex child-flex" cols="3">
                <v-img :src="att.path" aspect-ratio class="grey lighten-2"></v-img>
            </v-col>
        </v-row>

        <v-card-title> {{ x.title }} </v-card-title>
        <v-card-subtitle>{{ x.created | formatDate }}</v-card-subtitle>
        <v-card-text>{{ x.body }}</v-card-text>


        <v-card-actions class="px-4">
            <div>
                <div class="tooltip mx-3">{{ x.likesCount }}
                    <v-icon color="info lighten-2">mdi-thumb-up</v-icon>
                    <span v-if="likeTitle !== ''" class="tooltip-text">{{ likeTitle }}</span>
                </div>
                <div class="tooltip mx-3">
                    {{ x.disLikesCount }} <v-icon color="secondary">mdi-thumb-down</v-icon>
                    <span v-if="dislikeTitle !== ''" class="tooltip-text">{{ dislikeTitle }}</span>
                </div>
                <div class="tooltip mx-3">
                    {{ x.lovesCount }} <v-icon color="error lighten-2">mdi-heart</v-icon>
                    <span v-if="loveTitle !== ''" class="tooltip-text">{{ loveTitle }}</span>
                </div>
                <span class="mr-3"> {{ x.commentsCount }} <v-icon color="primary">mdi-comment</v-icon> </span>
            </div>
            <v-spacer></v-spacer>
            <v-btn text @click="show = !show">
                عرض التعليقات
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-card-actions>

        <v-expand-transition>
            <div v-show="show">
                <v-divider></v-divider>

                <CommentNode v-for="(comment) in comments" :key="comment.id" :node="comment" :spacing="10"
                    v-on:refresh="getNews" />


            </div>
        </v-expand-transition>


        <v-card-actions class="mt-2">
            <v-btn @click="goBack()" color="primary secondary--text" large> رجوع <v-icon>keyboard_arrow_left</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
import axios from "../../axios"
import CommentNode from "../../components/CommentNode.vue"
export default {
    components: {
        CommentNode
    },
    data() {
        return {
            x: {},// this.$route.params.obj,
            newsId: this.$route.params.id,
            show: false,
            comments: [],
            likeTitle: "",
            dislikeTitle: "",
            loveTitle: ""
        };
    },
    mounted() {
        this.getNews()
    },

    methods: {
        getNews() {
            this.$global.state.loading = true
            axios.get(`News/${this.newsId}`)
                .then((res) => {
                    this.x = res.data.result;
                    this.comments = res.data.result.comments;
                    this.likeTitle = res.data.result.likes.filter(like => like.type == 1).map(like => like.userFullname).join("\r\n\r\n");
                    this.dislikeTitle = res.data.result.likes.filter(like => like.type == 2).map(like => like.userFullname).join("\r\n\r\n");
                    this.loveTitle = res.data.result.likes.filter(like => like.type == 3).map(like => like.userFullname).join("\r\n\r\n");
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", { message: `${err.data.message}`, type: "error" });
                })
                .finally(() => (this.$global.state.loading = false));
        },
        goBack() {
            window.history.go(-1);
        },
        async deleteComment(item, i) {
            this.$global.state.loading = true;
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$http
                        .delete(`NewsComments/${item.id}`)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.x.comments.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        })
                        .finally(() => (this.$global.state.loading = false));
                }
            });
        },
    },
};
</script>
<style>
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;

}

.tooltip .tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: rgb(66, 62, 62);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: .5rem .5rem;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    white-space: pre-wrap;
}

.tooltip .tooltip-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:rgb(66, 62, 62) transparent transparent transparent;
    white-space: pre-wrap;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}
</style>