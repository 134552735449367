<template>
<div :style="nodeMargin">
    <v-card flat class="d-flex justify-space-between grey lighten-4 pa-2 mx-2 mb-2">
        <div class="d-flex justify-space-between">
            <v-avatar size="40">
                <v-img :src="node.imageUrl"></v-img>
            </v-avatar>
            <div>
                <v-card flat class="mr-2 px-4 py-1">
                    <div> {{ node.fullName }} </div>
                    <div> {{ node.body }} </div>
                </v-card>
                <div>
                    <v-btn x-small @click="openReplyDialog(node)">reply</v-btn>
                    <span class="mx-2" v-if="hasReplies" :class="toggleRepliesIcon" @click="toggleReplies"
                        @keypress="toggleReplies" />

                </div>
                <div class="font-small grey--text text--lighten-1 mr-2">{{ node.created |
                    timeSince }}</div>

                <div v-if="hasReplies" v-show="showReplies">
                    <CommentNode v-for="reply in node.replies" :key="reply.id" :node="reply" :spacing="spacing + 10"
                        v-on:refresh="refresh" />
                </div>

            </div>
            <v-btn v-if="!hasReplies" @click="deleteComment(node)" color="error" icon>
                <v-icon>delete</v-icon>
            </v-btn>
        </div>

    </v-card>
    <!-- 
    <b-alert show class="d-flex justify-content-between mb-1">
        {{ node.label }}
        <span v-if="hasChildren" :class="toggleChildrenIcon" @click="toggleChildren" @keypress="toggleChildren" />
    </b-alert> -->


    <v-dialog v-model="replyDialog" width="50%" transition="dialog-top-transition">
        <v-card flat class="card">
            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title>
                    کتابة تعلیق
                </v-toolbar-title>
                <v-spacer />
                <v-btn @click="closeReplyDialog()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="replySubmit()" ref="form" lazy-validation v-model="replyValid">
                <v-container>
                    <v-card outlined>
                        <v-container>
                            <v-row no-gutters>
                                <!-- states -->
                                <v-col cols="12" md="12">
                                    <h4 class="mb-2">
                                        تعلیق
                                        <span class="required">*</span>
                                    </h4>
                                    <v-textarea v-model="replyBody" required outlined
                                        :rules="[$global.state.required()]" filled rows="2" hide-details="auto"
                                        label="تعلیقک هنا" color="secondary"></v-textarea>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card>

                    <!-- actions -->
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :loading="$global.state.loading" x-large color="primary"
                                class="btn secondary--text" :disabled="!replyValid">حفظ</v-btn>
                            <v-btn @click="closeReplyDialog()" x-large color="error" class="mr-2 btn"
                                outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import axios from "../axios"
export default ({
    name: "CommentNode",
    props: {
        node: {
            type: Object,
            required: true
        },
        spacing: {
            type: Number,
            default: 0
        },

    },
    data() {
        return {
            showReplies: false,
            replyBody: "",
            replyDialog: false,
            replyValid: true,
            replyParentId: "",

        }
    },
    computed: {
        nodeMargin() {
            return {
                'margin-left': `${this.spacing}px`
            }
        },
        hasReplies() {
            const { replies } = this.node
            return replies && replies.length > 0
        },
        toggleRepliesIcon() {
            return this.showReplies ? 'fas fa-angle-down' : 'fas fa-angle-right'
        }
    },
    methods: {
        refresh() {
            this.$emit('refresh')
        },
        toggleReplies() {
            this.showReplies = !this.showReplies
        },
        closeReplyDialog() {
            this.replyDialog = false;
            this.reply = "";
            this.replyParentId = ""
        },
        openReplyDialog(node) {
            this.replyDialog = true;
            this.reply = "";
            this.replyParentId = node.id;

        },
        replySubmit() {
            this.$global.state.loading = true;
            const postData = {
                comment: this.replyBody,
                parentId: this.replyParentId,
                newsId: this.$route.params.id,
            };

            axios.post("NewsComments", postData)
                .then((res) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `تم اضافة التعلیق بنجاح`,
                        type: "success",
                    });
                    this.refresh()
                    this.closeReplyDialog()

                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", { message: `${err.data.message}`, type: "error" });
                })
                .finally(() => (this.$global.state.loading = false));
        },
        async deleteComment(item) {
            this.$global.state.loading = true;
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$http
                        .delete(`NewsComments/${item.id}`)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            // this.x.comments.splice(i, 1);
                            this.refresh()
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        })
                        .finally(() => (this.$global.state.loading = false));
                }
            });
        },

    }
})
</script>